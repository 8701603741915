import { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
	Box,
	TextField,
	Button,
	Grid,
	Typography,
	CircularProgress,
	Alert,
	Card,
	CardMedia,
	CardContent,
	IconButton,
	Tooltip,
	CardActionArea,
	DialogTitle,
	DialogContentText,
	DialogActions,
	Pagination,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import axiosInstance from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9,
		position: "relative",
	},
	overlay: {
		position: "absolute",
		top: "0",
		bottom: "0",
		left: "0",
		right: "0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(0,0,0,0.3)",
		opacity: 0,
		transition: "0.3s ease",
		"&:hover": {
			opacity: 1,
		},
	},
	copyIcon: {
		color: "white",
	},
}));

const ImageUpload = () => {
	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [previews, setPreviews] = useState([]);
	const [open, setOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [dialogOpen, setDialogOpen] = useState(false);
	const [currentImageId, setCurrentImageId] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8; // Number of items per page

	const handleClickOpen = (imageUrl) => {
		setCurrentImage(imageUrl);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const response = await axiosInstance.get("images");
				if (response.status !== 200) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				setImages(response.data);
			} catch (error) {
				console.error("Fetching images failed: ", error);
				setStatus({
					type: "error",
					message: "Failed to load uploaded images.",
				});
			}
		};

		fetchImages();
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: true,
		onDrop: (acceptedFiles) => {
			setSelectedFiles(
				acceptedFiles.map((file) => ({ file, name: file.name }))
			);
			setPreviews(acceptedFiles.map((file) => URL.createObjectURL(file)));
		},
	});

	const handleDeleteConfirmation = (imageId) => {
		setCurrentImageId(imageId);
		setDialogOpen(true);
	};

	const deleteImage = async () => {
		if (!currentImageId) return;
		try {
			setLoading(true);
			const response = await axiosInstance.delete(`/images/${currentImageId}`);
			if (response.status === 200) {
				setImages((prevImages) =>
					prevImages.filter((img) => img._id !== currentImageId)
				);
				enqueueSnackbar("Image deleted successfully!", { variant: "success" });
			} else {
				enqueueSnackbar("Failed to delete the image", { variant: "error" });
			}
		} catch (error) {
			console.error("Error deleting image:", error);
			enqueueSnackbar("Error deleting image", { variant: "error" });
		} finally {
			setDialogOpen(false);
			setLoading(false);
		}
	};

	const handleImageUpload = async () => {
		setLoading(true);
		for (const { file, name } of selectedFiles) {
			const filePath = name;
			try {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("name", name);

				const response = await axiosInstance.post("images/upload", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});

				const data = response.data;
				setImages((prevImages) => [...prevImages, data]);
				setStatus({
					type: "success",
					message: "Image uploaded successfully",
				});
			} catch (error) {
				console.error(error);
				setStatus({ type: "error", message: "Error uploading file" });
				setLoading(false);
			}
		}

		setSelectedFiles([]);
		setPreviews([]);
		setLoading(false);
	};

	const handleNameChange = (name, index) => {
		const newFiles = [...selectedFiles];
		newFiles[index].name = name;
		setSelectedFiles(newFiles);
	};

	const onCopy = () => {
		enqueueSnackbar("Copied", { variant: "success" });
	};

	// Pagination logic
	const filteredImages = images
		.filter((image) =>
			image.imageName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		.sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt));

	const totalPages = Math.ceil(filteredImages.length / itemsPerPage);
	const displayedImages = filteredImages.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	return (
		<Box sx={{ p: 2, bgcolor: "background.paper" }}>
			<Typography variant="h5" gutterBottom>
				Upload Images
			</Typography>
			<Box
				sx={{
					width: "100%",
					height: "200px",
					border: "1px dashed gray",
					p: 2,
					mb: 2,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<CloudUploadIcon color="action" style={{ fontSize: 72 }} />
				{selectedFiles.length > 0 ? (
					<p>{selectedFiles.map(({ file }) => file.name).join(", ")}</p>
				) : (
					<Typography variant="body2" color="textSecondary">
						Drag 'n' drop images here, or click to select images
					</Typography>
				)}
			</Box>
			{previews && selectedFiles && selectedFiles.length > 0 && (
				<Box
					sx={{
						mb: 2,
						maxWidth: "100%",
						height: "auto",
						display: "flex",
						flexWrap: "wrap",
						gap: 2,
					}}
				>
					{previews.map((preview, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<img
								src={preview}
								alt="Preview"
								style={{ maxWidth: "200px", height: "auto" }}
							/>
							<TextField
								margin="normal"
								name="imageName"
								label="Image Name"
								type="text"
								value={selectedFiles[index].name}
								onChange={(event) =>
									handleNameChange(event.target.value, index)
								}
								disabled={loading}
							/>
						</Box>
					))}
				</Box>
			)}
			{selectedFiles.length > 0 && !loading && (
				<Button variant="contained" color="primary" onClick={handleImageUpload}>
					Upload Images
				</Button>
			)}
			{loading && <CircularProgress />}

			{status && (
				<Alert severity={status.type} sx={{ mt: 2 }}>
					{status.message}
				</Alert>
			)}

			<TextField
				fullWidth
				margin="normal"
				variant="outlined"
				label="Search Images"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				placeholder="Search by name..."
			/>
			<Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
				Uploaded Images
			</Typography>

			<Grid container spacing={2}>
				{displayedImages.map((image, index) => (
					<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
						<Card className={classes.root}>
							<CardActionArea>
								<CardMedia
									className={classes.media}
									image={image.imageUrl}
									title={image.imageName}
								>
									<Box className={classes.overlay}>
										<CopyToClipboard text={image.imageUrl} onCopy={onCopy}>
											<Tooltip title="Copy to clipboard" placement="top">
												<IconButton>
													<FileCopyIcon className={classes.copyIcon} />
												</IconButton>
											</Tooltip>
										</CopyToClipboard>
										<Tooltip title="View Fullscreen" placement="top">
											<IconButton
												onClick={() => handleClickOpen(image.imageUrl)}
											>
												<FullscreenIcon className={classes.copyIcon} />
											</IconButton>
										</Tooltip>
										<Tooltip
											key={image._id}
											title="Delete Image"
											placement="top"
										>
											<IconButton
												onClick={() => handleDeleteConfirmation(image._id)}
											>
												<DeleteForever className={classes.copyIcon} />
											</IconButton>
										</Tooltip>
									</Box>
								</CardMedia>
							</CardActionArea>
							<CardContent>
								<Typography variant="h6" color="textPrimary" component="div">
									{image.name}
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									Created at: {moment(image.uploadedAt).format("MMM Do YYYY")}
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									Filename: {image.imageName}
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									Uploaded By: {image.uploadedBy}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Pagination component */}
			<Box display="flex" justifyContent="center" mt={2}>
				<Pagination
					count={totalPages}
					page={currentPage}
					onChange={handlePageChange}
					color="primary"
				/>
			</Box>

			{/* Fullscreen and delete confirmation dialogs */}
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogContent>
					<img
						src={currentImage}
						alt="Fullscreen view"
						style={{ width: "100%", height: "auto" }}
					/>
				</DialogContent>
			</Dialog>

			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>Delete Image</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this image?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} color="primary">
						Cancel
					</Button>
					<Button onClick={deleteImage} color="secondary" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

		</Box>
	);
};

export default ImageUpload;
