import {
	CircularProgress,
	Container,
	Box,
	Card,
	Button,
	Typography,
} from "@mui/material";
import {
	BlockManager,
	BasicType,
	AdvancedType,
	JsonToMjml,
} from "easy-email-core";
import { CustomBlocksType } from "./customBlocks/constants";
import {
	BlockAvatarWrapper,
	EmailEditor,
	EmailEditorProvider,
} from "easy-email-editor";
import { StandardLayout } from "easy-email-extensions";
import { useWindowSize } from "react-use";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import mjml from "mjml-browser";
import SvgIconStyle from "../../components/SvgIconStyle";
import Code from "@mui/icons-material/CodeRounded";
import { useState, useEffect, useRef, useCallback } from "react";
import { useSnackbar } from "notistack";
import { debounce, isEqual, cloneDeep, set } from "lodash";
import axios from "../../utils/axios"; // Ensure axios is configured correctly to hit the local API
import { useParams } from "react-router-dom";
import Handlebars from "handlebars";

const fontList = [
	"Arial",
	"Tahoma",
	"Verdana",
	"Times New Roman",
	"Courier New",
	"Georgia",
	"Lato",
	"Montserrat",
].map((item) => ({ value: item, label: item }));

const getIcon = (name) => (
	<SvgIconStyle
		src={`/static/icons/navbar/${name}.svg`}
		sx={{ width: "100%", height: "100%" }}
	/>
);

const categories = [
	{
		label: "Custom",
		active: true,
		displayType: "custom",
		blocks: [
			<BlockAvatarWrapper type={CustomBlocksType.PRACTICE_SIGNATURE}>
				<Box sx={{ p: 1 }}>
					<Card
						sx={{
							overflow: "auto",
							borderRadius: 0,
							p: 1,
							width: 100,
							height: 110,
						}}
					>
						<Box sx={{ p: 1, textAlign: "center" }}>
							<Code />
							<Typography variant="body2">{"Practice Signature"}</Typography>
						</Box>
					</Card>
				</Box>
			</BlockAvatarWrapper>,
			<BlockAvatarWrapper type={CustomBlocksType.PRACTICE_LOGO}>
				<Box sx={{ p: 1 }}>
					<Card
						sx={{
							overflow: "auto",
							borderRadius: 0,
							p: 1,
							width: 100,
							height: 110,
						}}
					>
						<Box sx={{ p: 1, textAlign: "center" }}>
							<Code />
							<Typography variant="body2">{"Practice Logo"}</Typography>
						</Box>
					</Card>
				</Box>
			</BlockAvatarWrapper>,
		],
	},
	{
		label: "Content",
		active: true,
		blocks: [
			{ type: AdvancedType.TEXT },
			{ type: AdvancedType.IMAGE },
			{ type: AdvancedType.BUTTON },
			{ type: AdvancedType.SOCIAL },
			{ type: AdvancedType.DIVIDER },
			{ type: AdvancedType.SPACER },
			{ type: AdvancedType.HERO },
			{ type: AdvancedType.NAVBAR },
			{ type: AdvancedType.ACCORDION },
			{ type: AdvancedType.CAROUSEL },
			{ type: AdvancedType.WRAPPER },
			{ type: AdvancedType.SECTION },
			{ type: AdvancedType.COLUMN },
			{ type: AdvancedType.GROUP },
		],
	},
	{
		label: "Layout",
		active: true,
		displayType: "column",
		blocks: [
			{
				title: "2 columns",
				payload: [
					["50%", "50%"],
					["33%", "67%"],
					["67%", "33%"],
					["25%", "75%"],
					["75%", "25%"],
				],
			},
			{
				title: "3 columns",
				payload: [
					["33.33%", "33.33%", "33.33%"],
					["25%", "25%", "50%"],
					["50%", "25%", "25%"],
				],
			},
			{ title: "4 columns", payload: [["25%", "25%", "25%", "25%"]] },
		],
	},
];

const initialValues = {
	subject: "Welcome to Verticalvet Connect",
	subTitle: "Nice to meet you!",
	content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
};

export default function LiveEmailEditor({ json, onUpdate, practiceId }) {
	const { width } = useWindowSize();
	const { themeStretch } = useSettings();
	const { enqueueSnackbar } = useSnackbar();
	const [editorContent, setEditorContent] = useState(json || initialValues);
	const previousValuesRef = useRef(editorContent);
	const [isSaving, setIsSaving] = useState(false);
	const [mergeTags, setMergeTags] = useState({
		practice: {
			name: "",
			website: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			phone: "",
			logo: "",
			logosm: "",
			color: "",
			emailRemindersReplyTo: "",
			cmykColor: "",
		},
		petName: "",
		clientName: "",
		emailAddress: "",
		phoneNumber: "",
	});

	// Fetch the practice details and logo and update the mergeTags
	useEffect(() => {
		if (practiceId) {
			const fetchPracticeDetails = async () => {
				try {
					const detailsResponse = await axios.get(
						`/campaign/getPracticeDetails?practiceId=${practiceId}`
					);
					const practiceDetails = detailsResponse.data;

					const logoResponse = await axios.get(
						`campaign/getPracticeLogo?practiceId=${practiceId}`
					);
					const practiceLogo = logoResponse.data;

					setMergeTags((prev) => ({
						...prev,
						practice: {
							...prev.practice,
							name: practiceDetails.name || prev.practice.name,
							website: practiceDetails.website || prev.practice.website,
							address1: practiceDetails.address1 || prev.practice.address1,
							address2: practiceDetails.address2 || prev.practice.address2,
							city: practiceDetails.city || prev.practice.city,
							state: practiceDetails.state || prev.practice.state,
							zip: practiceDetails.zip || prev.practice.zip,
							phone: practiceDetails.phone || prev.practice.phone,
							logo: practiceLogo || prev.practice.logo,
							emailRemindersReplyTo:
								practiceDetails.emailRemindersReplyTo ||
								prev.practice.emailRemindersReplyTo,
							color: practiceDetails.color || prev.practice.color,
							cmykColor: practiceDetails.cmykColor || prev.practice.cmykColor,
						},
					}));
				} catch (error) {
					console.error("Failed to fetch practice details or logo", error);
					enqueueSnackbar("Failed to load practice information", {
						variant: "error",
					});
				}
			};

			fetchPracticeDetails();
		}
	}, [practiceId, enqueueSnackbar]);

	useEffect(() => {
		if (!isEqual(json, previousValuesRef.current)) {
			setEditorContent(json);
			previousValuesRef.current = json;
		}
	}, [json]);

	const debouncedSave = useCallback(
		debounce((values) => {
			if (isSaving) {
				return;
			}

			const mjmlString = JsonToMjml({
				data: values.content,
				mode: "production",
				context: values.content,
				dataSource: mergeTags,
			});

			// Compile the MJML string to HTML and apply the merge tags
			const compiledHtml = mjml(mjmlString, {}).html;

			const payload = {
				json: JSON.stringify(values),
				html: compiledHtml,
			};

			setIsSaving(true);

			setTimeout(() => {
				setIsSaving(false);
				previousValuesRef.current = values;
				onUpdate && onUpdate(payload);
				enqueueSnackbar("Saved Successfully", { variant: "success" });
			}, 1000);
		}, 200),
		[isSaving, onUpdate, enqueueSnackbar, mergeTags]
	);

	const handleSave = (values) => {
		debouncedSave(values);
		setEditorContent(values);
	};

	const smallScene = width < 1400;

	return (
		<Page>
			<Container maxWidth={themeStretch ? false : "l"}>
				<EmailEditorProvider
					data={editorContent}
					height={"calc(100vh - 72px)"}
					width={"100%"}
					autoComplete
					dashed={false}
					mergeTags={mergeTags}
					onChangeMergeTag={(path, val) => {
						setMergeTags((old) => {
							const newObj = cloneDeep(old);
							set(newObj, path, val);
							return newObj;
						});
					}}
					mergeTagGenerate={(tag) => `{{${tag}}}`}
					fontList={fontList}
					onBeforePreview={(html) => {
						const template = Handlebars.compile(html);
						return template(mergeTags);
					}}
				>
					{({ values }) => {
						return (
							<StandardLayout
								compact={!smallScene}
								categories={categories}
								showSourceCode={false}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() => handleSave(values)}
									disabled={isSaving}
									sx={{ margin: 2 }}
								>
									{isSaving ? "Saving..." : "Save"}
								</Button>
								<EmailEditor />
							</StandardLayout>
						);
					}}
				</EmailEditorProvider>
			</Container>
		</Page>
	);
}
