import { BlockManager } from "easy-email-core";
import { BlockAttributeConfigurationManager } from "easy-email-extensions";
import { CustomBlocksType } from "./constants";
import {
	Panel as ProductRecommendationPanel,
	ProductRecommendation,
} from "./ProductRecommendation";
import {
	Panel as PracticeSignaturePanel,
	PracticeSignature,
} from "./PracticeSignature";

import {
	Panel as PracticeLogoPanel,
	PracticeLogo,
} from "./PracticeLogo";


BlockManager.registerBlocks({
	[CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendation,
	[CustomBlocksType.PRACTICE_SIGNATURE]: PracticeSignature,
    [CustomBlocksType.PRACTICE_LOGO]: PracticeLogo,
});

BlockAttributeConfigurationManager.add({
	[CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendationPanel,
	[CustomBlocksType.PRACTICE_SIGNATURE]: PracticeSignaturePanel,
    [CustomBlocksType.PRACTICE_LOGO]: PracticeLogoPanel,
});
