import { useEffect, useState } from "react";
import {
	Box,
	Button,
	Typography,
	CircularProgress,
	Alert,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import axiosInstance from "../../utils/axios";
import { useSnackbar } from "notistack";

const CampaignFileUpload = ({ campaignId }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [selectedFile, setSelectedFile] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [fetchingData, setFetchingData] = useState(false);
	const [status, setStatus] = useState(null);
	const [fileName, setFileName] = useState("");
	const [tableData, setTableData] = useState([]);

	// Sample files URLs
	const sampleDataUrl =
		"https://mediaverticalvet.s3.us-east-1.amazonaws.com/partial_sample_data.xlsx";
	const completeSampleDataUrl =
		"https://mediaverticalvet.s3.us-east-1.amazonaws.com/complete_sample_data.xlsx";

	// Pagination state
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		fetchData();
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"text/csv": [".csv"],
			"application/vnd.ms-excel": [".xls"],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
				".xlsx",
			],
		},
		multiple: false,
		onDrop: (acceptedFiles) => {
			const file = acceptedFiles[0];
			if (file) {
				setSelectedFile(file);
				setFileName(file.name);
			}
		},
	});

	const handleFileUpload = async () => {
		if (!selectedFile) {
			enqueueSnackbar("Please select a file first!", { variant: "warning" });
			return;
		}
		setUploading(true);
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("name", fileName);
		formData.append("campaignId", campaignId);

		try {
			const response = await axiosInstance.post("/campaign/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.status === 200) {
				setStatus({
					type: "success",
					message: "File uploaded successfully.",
				});
				// Fetch data after successful upload
				await fetchData();
			} else {
				setStatus({
					type: "error",
					message: "Error uploading the file.",
				});
			}
		} catch (error) {
			setStatus({
				type: "error",
				message: "An error occurred while uploading the file.",
			});
		} finally {
			setUploading(false);
			setSelectedFile(null);
			setFileName("");
		}
	};

	const fetchData = async () => {
		try {
			setFetchingData(true);
			const response = await axiosInstance.get(`/campaign/data/${campaignId}`);
			if (response.status === 200) {
				const data = response.data;
				if (data.length === 0) {
					setStatus({
						type: "info",
						message: "No data available for this campaign.",
					});
				}
				setTableData(data);
			} else {
				enqueueSnackbar("Error fetching data", { variant: "error" });
			}
		} catch (error) {
			// enqueueSnackbar("An error occurred while fetching data", {
			// 	variant: "error",
			// });
		} finally {
			setFetchingData(false);
		}
	};

	// Pagination Handlers
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0); // Reset page to 0 on row change
	};

	return (
		<Box sx={{ p: 2, bgcolor: "background.paper" }}>
			<Typography variant="h5" gutterBottom>
				Upload Campaign Data
			</Typography>

			{/* Download Buttons for Sample Files */}
			<Box sx={{ display: "flex", gap: 2, mb: 2 }}>
				<Button variant="outlined" component="a" href={sampleDataUrl} download>
					Download Sample Data File
				</Button>
				<Button
					variant="outlined"
					component="a"
					href={completeSampleDataUrl}
					download
				>
					Download Complete Sample Data File
				</Button>
			</Box>

			<Box
				sx={{
					width: "100%",
					height: "200px",
					border: "1px dashed gray",
					p: 2,
					mb: 2,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<CloudUploadIcon color="action" style={{ fontSize: 72 }} />
				{selectedFile ? (
					<Typography>{fileName}</Typography>
				) : (
					<Typography variant="body2" color="textSecondary">
						Drag and drop the campaign data file here, or click to select the
						file
					</Typography>
				)}
			</Box>
			{selectedFile && (
				<TextField
					fullWidth
					margin="normal"
					label="File Name"
					variant="outlined"
					value={fileName}
					onChange={(e) => setFileName(e.target.value)}
				/>
			)}
			{selectedFile && !uploading && (
				<Button
					variant="contained"
					color="primary"
					onClick={handleFileUpload}
					sx={{ mt: 2 }}
				>
					Upload File
				</Button>
			)}
			{(uploading || fetchingData) && (
				<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
					<CircularProgress />
				</Box>
			)}
			{status && (
				<Alert severity={status.type} sx={{ mt: 2 }}>
					{status.message}
				</Alert>
			)}
			{tableData.length > 0 ? (
				<TableContainer component={Paper} sx={{ mt: 2 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Pet Name</TableCell>
								<TableCell>Client Name</TableCell>
								<TableCell>Email Address</TableCell>
								<TableCell>Phone Number</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<TableRow key={index}>
										<TableCell>{row.petName || "N/A"}</TableCell>
										<TableCell>{row.clientName || "N/A"}</TableCell>
										<TableCell>{row.emailAddress || "N/A"}</TableCell>
										<TableCell>{row.phoneNumber || "N/A"}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={tableData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</TableContainer>
			) : (
				!fetchingData && (
					<Typography sx={{ mt: 2 }} align="center" color="textSecondary">
						No data available to display.
					</Typography>
				)
			)}
		</Box>
	);
};

export default CampaignFileUpload;
