// material
import { CircularProgress, Container, Menu } from "@mui/material";
// Register external blocks
import "./customBlocks";
import {
	BlockManager,
	BasicType,
	AdvancedType,
	JsonToMjml,
} from "easy-email-core";
import {
	BlockAvatarWrapper,
	EmailEditor,
	EmailEditorProvider,
	useBlock,
} from "easy-email-editor";
import { ExtensionProps, StandardLayout } from "easy-email-extensions";
import { useWindowSize } from "react-use";
import { BlockMarketManager, BlockMaskWrapper } from "easy-email-extensions";
import { CustomBlocksType } from "./customBlocks/constants";
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import mjml from "mjml-browser";
import SvgIconStyle from "../../components/SvgIconStyle";
import Code from "@mui/icons-material/CodeRounded";
import { saveAs } from "file-saver";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { ImageManager } from "easy-email-core";
import {
	Box,
	Grid,
	Link,
	Card,
	Button,
	Avatar,
	Typography,
	CardContent,
} from "@mui/material";
import { debounce, isEqual, cloneDeep, set } from "lodash";
import { useSnackbar } from "notistack";
import { Liquid } from "liquidjs";

const getIcon = (name) => (
	<SvgIconStyle
		src={`/static/icons/navbar/${name}.svg`}
		sx={{ width: "100%", height: "100%" }}
	/>
);
const fontList = [
	"Arial",
	"Tahoma",
	"Verdana",
	"Times New Roman",
	"Courier New",
	"Georgia",
	"Lato",
	"Montserrat",
].map((item) => ({ value: item, label: item }));

const categories = [
	{
		label: "Custom",
		active: true,
		displayType: "custom",
		blocks: [
			<BlockAvatarWrapper type={CustomBlocksType.PRACTICE_SIGNATURE}>
				<Box sx={{ p: 1 }}>
					<Card
						sx={{
							overflow: "auto",
							borderRadius: 0,
							p: 1,
							width: 100,
							height: 110,
						}}
					>
						<Box sx={{ p: 1, textAlign: "center" }}>
							<Code />
							<Typography variant="body2">{"Practice Signature"}</Typography>
						</Box>
					</Card>
				</Box>
			</BlockAvatarWrapper>,
			<BlockAvatarWrapper type={CustomBlocksType.PRACTICE_LOGO}>
				<Box sx={{ p: 1 }}>
					<Card
						sx={{
							overflow: "auto",
							borderRadius: 0,
							p: 1,
							width: 100,
							height: 110,
						}}
					>
						<Box sx={{ p: 1, textAlign: "center" }}>
							<Code />
							<Typography variant="body2">{"Practice Logo"}</Typography>
						</Box>
					</Card>
				</Box>
			</BlockAvatarWrapper>,
		],
	},
	{
		label: "Content",
		active: true,
		blocks: [
			{
				type: AdvancedType.TEXT,
			},
			{
				type: AdvancedType.IMAGE,
				payload: { attributes: { padding: "0px 0px 0px 0px" } },
			},
			{
				type: AdvancedType.BUTTON,
			},
			{
				type: AdvancedType.SOCIAL,
			},
			{
				type: AdvancedType.DIVIDER,
			},
			{
				type: AdvancedType.SPACER,
			},
			{
				type: AdvancedType.HERO,
			},
			{
				type: AdvancedType.NAVBAR,
			},
			{
				type: AdvancedType.ACCORDION,
			},
			{
				type: AdvancedType.CAROUSEL,
			},
			{
				type: AdvancedType.WRAPPER,
			},
			{
				type: AdvancedType.SECTION,
			},
			{
				type: AdvancedType.COLUMN,
			},
			{
				type: AdvancedType.GROUP,
			},
		],
	},
	{
		label: "Layout",
		active: true,
		displayType: "column",
		blocks: [
			{
				title: "2 columns",
				payload: [
					["50%", "50%"],
					["33%", "67%"],
					["67%", "33%"],
					["25%", "75%"],
					["75%", "25%"],
				],
			},
			{
				title: "3 columns",
				payload: [
					["33.33%", "33.33%", "33.33%"],
					["25%", "25%", "50%"],
					["50%", "25%", "25%"],
				],
			},
			{
				title: "4 columns",
				payload: [[["25%", "25%", "25%", "25%"]]],
			},
		],
	},
];

const initialValues = {
	subject: "Welcome to Verticalvet Connect",
	subTitle: "Nice to meet you!",
	content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
};

export default function EasyEmailEditor() {
	const { width } = useWindowSize();
	const { themeStretch } = useSettings();
	const { templateId, practiceId } = useParams(); // Assuming practiceId is passed as a route param
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [templateDetails, setTemplateDetails] = useState(null);
	const previousValuesRef = useRef();
	const [isSaving, setIsSaving] = useState(false);
	const shouldRetrySave = useRef(false);
	const [lastValuesToSave, setLastValuesToSave] = useState(null);
	const [editorContent, setEditorContent] = useState(initialValues);
	const [mergeTags, setMergeTags] = useState({
		practice: {
			name: "",
			website: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			phone: "",
			logo: "",
			logosm: "",
			color: "",
			emailRemindersReplyTo: "",
			cmykColor: "",
		},
        petName: "",
        clientName: "",
        emailAddress: "",
        phoneNumber: "",
	});

	useEffect(() => {
		const fetchTemplate = async () => {
			setIsLoading(true);
			try {
				const response = await axios.get(`/templates/${templateId}`);
				setEditorContent(JSON.parse(response.data.json) || initialValues);
				setTemplateDetails(response.data);
			} catch (error) {
				console.error("Failed to fetch template:", error);
			} finally {
				setIsLoading(false);
			}
		};

		if (templateId) {
			fetchTemplate();
		}
	}, [templateId]);

	const debouncedSave = useCallback(
		debounce((values) => {
			if (isSaving) {
				setLastValuesToSave(values);
				return;
			}

			const mjmlString = JsonToMjml({
				data: values.content,
				mode: "production",
				context: values.content,
				dataSource: mergeTags,
			});

			const payload = {
				json: JSON.stringify(values),
				html: mjml(mjmlString, {}).html,
			};

			setIsSaving(true);

			axios
				.put(`/templates/${templateId}`, payload)
				.then((response) => {
					setIsSaving(false);
					enqueueSnackbar("Saved Successfully", { variant: "success" });
				})
				.catch((error) => {
					setIsSaving(false);
					enqueueSnackbar("Error while saving", { variant: "error" });
				});
		}, 10000),
		[isSaving, mergeTags]
	);

	const smallScene = width < 1400;

	return (
		<Page>
			<Container maxWidth={themeStretch ? false : "xl"}>
				{isLoading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100vh",
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<EmailEditorProvider
						data={editorContent}
						height={"calc(100vh - 72px)"}
						width={"100%"}
						autoComplete
						dashed={false}
						fontList={fontList}
						mergeTags={mergeTags}
						onChangeMergeTag={(path, val) => {
							setMergeTags((old) => {
								const newObj = cloneDeep(old);
								set(newObj, path, val);
								return newObj;
							});
						}}
						mergeTagGenerate={(tag) => `{{${tag}}}`}
						onBeforePreview={(html) => {
							const engine = new Liquid();
							const tpl = engine.parse(html);
							return engine.renderSync(tpl, mergeTags);
						}}
					>
						{({ values }, { submit, restart }) => {
							debouncedSave(values);
							return (
								<>
									<StandardLayout
										compact={!smallScene}
										categories={categories}
										showSourceCode={true}
									>
										<Button
											variant="contained"
											color="primary"
											onClick={() => debouncedSave(values)}
											disabled={isSaving}
											sx={{ margin: 2 }}
										>
											{isSaving ? "Saving..." : "Save"}
										</Button>
										<EmailEditor />
									</StandardLayout>
								</>
							);
						}}
					</EmailEditorProvider>
				)}
			</Container>
		</Page>
	);
}
