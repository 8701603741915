import {
	IBlockData,
	BasicType,
	components,
	createCustomBlock,
	getPreviewClassName,
	AdvancedType,
} from "easy-email-core";

import { CustomBlocksType } from "../constants";
import { merge } from "lodash";

const { Section, Wrapper, Image } = components;

export const PracticeLogo = createCustomBlock({
	name: "Practice Logo",
	type: CustomBlocksType.PRACTICE_LOGO,
	validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
	create: (payload) => {
		const defaultData = {
			type: CustomBlocksType.PRACTICE_LOGO,
			data: {
				value: {
					logoUrl: "cid:practiceLogo.png",
                    logoWidth: "300px",
                    logoHeight: "200px",
					logoAlt: "{{practice.name}}",
				},
			},
			attributes: {
				"background-color": "#FFFFFF",
				padding: "10px 10px",
				"border-radius": "8px",
			},
			children: [],
		};
		return merge(defaultData, payload);
	},
	render: ({ data, idx, mode }) => {
		const { logoWidth, logoHeight, logoAlt } = data.data.value;
		const attributes = data?.attributes;

		return (
			<Wrapper
				css-class={
					mode === "testing" ? getPreviewClassName(idx, data?.type) : ""
				}
				padding={attributes.padding}
				border="none"
				direction="ltr"
				background-color={attributes["background-color"]}
				style={{
					backgroundColor: attributes["background-color"],
					padding: attributes.padding,
					borderRadius: attributes["border-radius"],
				}}
			>
				<Section
					padding="0px"
					style={{
						padding: "0px",
						textAlign: "center",
					}}
				>
					<mj-column>
						<mj-image
							align="center"
							height={logoHeight} // auto height for responsive scaling
							width={logoWidth}
							padding="10px"
							src="cid:practiceLogo.png"
                            href="{{practice.website}}"
                            target="_blank"
							alt={logoAlt}
							style={{
								width: logoWidth,
								height: "auto", // Ensures proportional scaling
								padding: "10px",
								maxWidth: "100%",
								display: "block",
								border: "0",
								lineHeight: "100%",
								textDecoration: "none",
								outline: "none",
								msInterpolationMode: "bicubic",
							}}
						/>
					</mj-column>
				</Section>
			</Wrapper>
		);
	},
});

export { Panel } from "./panel";
