import { useState } from "react";
import axios from '../../utils/axios';

// material
import {
	Button,
	Dialog,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	DialogTitle,
	FormControl,
	DialogActions,
	DialogContent,
	DialogContentText,
	CircularProgress,
	Snackbar,
} from "@mui/material";

const createTemplate = async (templateData) => {
	try {
		const response = await axios.post("/templates", {
			name: templateData.templateName, // Adjusting to the new naming convention
			type: templateData.templateType,
		});

		return response.data;
	} catch (error) {
		console.error(
			"Error creating the template:",
			error.response?.data || error.message
		);
		throw error;
	}
};

export default function TemplateCreationDialog({ open, onClose, onOpen, onContinue }) {
	const [templateData, setTemplateData] = useState({
		templateName: "",
		templateType: "",
	});
	const [loading, setLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const handleChange = (field) => (event) => {
		setTemplateData((prevData) => ({
			...prevData,
			[field]: event.target.value,
		}));
	};

	const handleContinue = async () => {
		setLoading(true);
		try {
			const response = await createTemplate(templateData);
			setSnackbarMessage("Template created successfully!");
			onClose();
            onContinue(response);
		} catch (error) {
			setSnackbarMessage("Failed to create the template");
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} fullWidth>
				<DialogTitle>Add New Template</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Fill in the basic information for your new template.
					</DialogContentText>

					<FormControl fullWidth margin="normal">
						<TextField
							label="Template Name"
							value={templateData.templateName}
							onChange={handleChange("templateName")}
							variant="outlined"
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Template Type</InputLabel>
						<Select
							value={templateData.templateType}
							onChange={handleChange("templateType")}
							variant="outlined"
						>
							{/* Assuming the TemplateType enum has values 'Type1', 'Type2', etc. Adjust as per your actual values */}
							<MenuItem value="email">Email</MenuItem>
							<MenuItem value="text">Text</MenuItem>
							{/* Add more MenuItem components for other template types */}
						</Select>
					</FormControl>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose} variant="contained" disabled={loading}>
						Cancel
					</Button>
					<Button
						onClick={handleContinue}
						variant="contained"
						color="primary"
						disabled={
							!templateData.templateName ||
							!templateData.templateType ||
							loading
						}
					>
						{loading ? <CircularProgress size={24} /> : "Continue"}
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={!!snackbarMessage}
				onClose={() => setSnackbarMessage("")}
				message={snackbarMessage}
				autoHideDuration={3000}
			/>
		</>
	);
}
