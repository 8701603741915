import {
	IBlockData,
	BasicType,
	components,
	createCustomBlock,
	getPreviewClassName,
	AdvancedType,
} from "easy-email-core";

import { CustomBlocksType } from "../constants";
import { merge } from "lodash";
import { getContentEditableClassName } from "easy-email-editor";

const { Column, Group, Section, Wrapper, Text, Button } = components;

type CustomSignatureProps = {
	practiceName: string,
	email: string,
	address1: string,
	address2: string,
	city: string,
	state: string,
	zip: string,
	phone: string,
	website: string,
	textColor: string,
	fontFamily: string,
};

export const PracticeSignature = createCustomBlock({
	name: "Practice signature",
	type: CustomBlocksType.PRACTICE_SIGNATURE,
	validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER, AdvancedType.TEXT],
	create: (payload) => {
		const defaultData = {
			type: CustomBlocksType.PRACTICE_SIGNATURE,
			data: {
				value: {
					practiceName: "{{practice.name}}",
					address1: "{{practice.address1}}",
					address2: "{{practice.address2}}",
					city: "{{practice.city}}",
					state: "{{practice.state}}",
					zip: "{{practice.zip}}",
					phone: "{{practice.phone}}",
					website: "{{practice.website}}",
				},
			},
			attributes: {
				"text-color": "#333333",
				"font-family": "Arial, sans-serif", // Default font family
			},
			children: [
				{
					type: AdvancedType.TEXT,
					children: [],
					data: {
						value: {
							content: "Practice Signature",
						},
					},
					attributes: {},
				},
			],
		};
		return merge(defaultData, payload);
	},
	render: ({ data, idx, mode }) => {
		const { practiceName, email, address1, address2, city, state, zip, phone, website } = data.data.value;
		const attributes = data?.attributes;

		return (
			<Wrapper
				css-class={
					mode === "testing" ? getPreviewClassName(idx, data?.type) : ""
				}
				padding="20px 0px 20px 0px"
				border="none"
				direction="ltr"
				text-align="left"
				background-color={attributes["background-color"]}
			>
				<Section padding="0px">
					<Text
						padding="10px 25px 10px 25px"
						align="left"
						color={attributes["text-color"]}
						font-family={attributes["font-family"]}
						css-class={getContentEditableClassName(
							BasicType.TEXT,
							`${idx}.data.value`
						).join(" ")}
					>
						{`<div contentEditable="true">The Doctors & Staff at ${practiceName}</div>
						<div contentEditable="true">${address1} ${address2}</div>
						<div contentEditable="true">${city} ${state} ${zip}</div>
						<div contentEditable="true">${phone}</div>
						<div contentEditable="true">${website}</div>`}
					</Text>
				</Section>
			</Wrapper>
		);
	},
});

export { Panel } from "./panel";
