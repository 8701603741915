import { Stack } from "@mui/material";
import { useFocusIdx } from "easy-email-editor";
import {
    AttributesPanelWrapper,
    ColorPickerField,
    TextField,
} from "easy-email-extensions";
import React from "react";

export function Panel() {
    const { focusIdx } = useFocusIdx();
    return (
        <AttributesPanelWrapper style={{ padding: "20px" }}>
            <Stack>
                <TextField
                    label="Logo URL"
                    name={`${focusIdx}.data.value.logoUrl`}
                    inline
                    alignment="center"
                />
                <TextField
                    label="Logo Width"
                    name={`${focusIdx}.data.value.logoWidth`}
                    inline
                    alignment="center"
                />
                <TextField
                    label="Logo Height"
                    name={`${focusIdx}.data.value.logoHeight`}
                    inline
                    alignment="center"
                />
                <TextField
                    label="Logo Alt Text"
                    name={`${focusIdx}.data.value.logoAlt`}
                    inline
                    alignment="center"
                />
                <ColorPickerField
                    label="Background color"
                    name={`${focusIdx}.attributes.background-color`}
                    inline
                    alignment="center"
                />
                <TextField
                    label="Padding"
                    name={`${focusIdx}.attributes.padding`}
                    inline
                    alignment="center"
                />
            </Stack>
        </AttributesPanelWrapper>
    );
}