import { Stack } from "@mui/material";
import { useFocusIdx } from "easy-email-editor";
import {
	AttributesPanelWrapper,
	ColorPickerField,
	TextField,
	SelectField,
} from "easy-email-extensions";
import React from "react";

export function Panel() {
	const { focusIdx } = useFocusIdx();
	return (
		<AttributesPanelWrapper style={{ padding: "20px" }}>
			<Stack>
				<TextField
					label="Practice Name"
					name={`${focusIdx}.data.value.practiceName`}
					inline
					alignment="center"
				/>
				<TextField
					label="Email"
					name={`${focusIdx}.data.value.email`}
					inline
					alignment="center"
				/>
				<TextField
					label="Address Line 1"
					name={`${focusIdx}.data.value.address1`}
					inline
					alignment="center"
				/>
				<TextField
					label="Address Line 2"
					name={`${focusIdx}.data.value.address2`}
					inline
					alignment="center"
				/>
				<TextField
					label="City"
					name={`${focusIdx}.data.value.city`}
					inline
					alignment="center"
				/>
				<TextField
					label="State"
					name={`${focusIdx}.data.value.state`}
					inline
					alignment="center"
				/>
				<TextField
					label="ZIP"
					name={`${focusIdx}.data.value.zip`}
					inline
					alignment="center"
				/>
				<TextField
					label="Phone"
					name={`${focusIdx}.data.value.phone`}
					inline
					alignment="center"
				/>
				<TextField
					label="Website"
					name={`${focusIdx}.data.value.website`}
					inline
					alignment="center"
				/>
				<ColorPickerField
					label="Text color"
					name={`${focusIdx}.attributes.text-color`}
					inline
					alignment="center"
				/>
				<ColorPickerField
					label="Background color"
					name={`${focusIdx}.attributes.background-color`}
					inline
					alignment="center"
				/>
				<SelectField
					label="Font family"
					name={`${focusIdx}.attributes.font-family`}
					inline
					options={[
						{ value: "Arial, sans-serif", label: "Arial" },
						{ value: "'Times New Roman', serif", label: "Times New Roman" },
						{ value: "Georgia, serif", label: "Georgia" },
						{ value: "'Courier New', monospace", label: "Courier New" },
						{ value: "'Trebuchet MS', sans-serif", label: "Trebuchet MS" },
					]}
				/>
				<TextField
					label="Padding"
					name={`${focusIdx}.attributes.padding`}
					placeholder="e.g., 10px 20px 10px 20px"
					inline
					alignment="center"
				/>
				<TextField
					label="Margin"
					name={`${focusIdx}.attributes.margin`}
					placeholder="e.g., 5px 0px 5px 0px"
					inline
					alignment="center"
				/>
				<SelectField
					label="Text alignment"
					name={`${focusIdx}.attributes.text-align`}
					inline
					options={[
						{ value: "left", label: "Left" },
						{ value: "center", label: "Center" },
						{ value: "right", label: "Right" },
					]}
				/>
			</Stack>
		</AttributesPanelWrapper>
	);
}
