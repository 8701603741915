import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  label: {
    fontWeight: 'bold',
  },
}));

const CampaignSummary = ({ campaign, campaignType, filters, campaignKind }) => {
  const classes = useStyles();

  const filtersApplied = Object.entries(filters)
    .filter(([key, value]) => value !== '' && value !== 'both' && key !== 'startAge' && key !== 'endAge')
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');

  const ageFiltersApplied = Object.entries(filters)
    .filter(([key, value]) => value !== '' && (key === 'startAge' || key === 'endAge'))
    .map(([key, value]) => `${key}: ${value}`)
    .join(' - ');

  const summaryText = `${campaign ? `Campaign: ${campaign} | ` : ''}${campaignType ? `Type: ${campaignType} | ` : ''}${ageFiltersApplied ? `Age Range: ${ageFiltersApplied} | ` : ''}${filtersApplied ? `Filters: ${filtersApplied} | ` : ''}${campaignKind ? `Kind: ${campaignKind}` : ''}`;

  return (
    <div className={classes.root}>
      <Typography variant="body2">{summaryText}</Typography>
    </div>
  );
};

export default CampaignSummary;
