import PropTypes from "prop-types";
// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
	sx: PropTypes.object,
};

export default function Logo({ sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	return (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1000 1000"
			>
				<defs>
					<linearGradient
						id="a"
						x1={56.28}
						y1={300.27}
						x2={1090.63}
						y2={765.87}
						gradientUnits="userSpaceOnUse"
					>
						<stop offset={0} stopColor={PRIMARY_MAIN} />
						<stop offset={1} stopColor={PRIMARY_LIGHT} />
					</linearGradient>
				</defs>
				<path
					d="M500 0C223.86 0 0 223.86 0 500s223.86 500 500 500 500-223.86 500-500S776.14 0 500 0Zm65.21 424.09c-92-63.24-306.64-113.58-424-124.82 84.4-4.77 325.06 38.26 424 124.82ZM390.38 694.62c64.52-125.8 54.43-220.33 1.23-284.7 80.16 77.14 83.1 212.95-1.23 284.7ZM538.66 615c-34.59 33.83-73.9 53.72-127 86.09 166.52-232.73-23-358.47-291-384.13 129.11-7.3 623.48 97.12 418 298.04Zm13-7c31.89-33.5 44.25-64.25 42.16-92.18L879.29 335Z"
					style={{
						fill: "url(#a)",
					}}
				/>
			</svg>
		</Box>
	);
}
