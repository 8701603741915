import {
	Container,
	Grid,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Typography,
	CircularProgress, // Import CircularProgress for the loader
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button,
} from "@mui/material";
import Page from "../../components/Page";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TemplateCreationDialog from "./TemplateCreationDialog";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { format } from "date-fns"; // Import date formatting library

const useStyles = makeStyles((theme) => ({
	addCard: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	card: {
		borderRadius: "10px",
		boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
		transition: "all 0.3s",
		"&:hover": {
			transform: "translateY(-3px)",
			boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
		},
	},
	selectedCard: {
		border: `2px solid ${theme.palette.primary.main}`,
	},
	media: {
		height: 200,
		backgroundPosition: "top",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	content: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 600,
		fontSize: "1.25rem",
		color: "#333",
	},
	detailsGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gridColumnGap: theme.spacing(2),
		marginTop: theme.spacing(1),
	},
	detail: {
		fontSize: "0.75rem",
		color: "#666",
	},
	loader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 200,
	},
}));

export default function TemplateSelector({ onSelect }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedTemplateId, setSelectedTemplateId] = useState(null);

	const navigate = useNavigate();

	const handleTemplateSelection = async (templateId) => {
		setLoading(true);
		setSelectedTemplateId(templateId);
		try {
			const response = await axios.get(`/templates/${templateId}`);
			if (response.data) {
				const { json, html } = response.data;
				onSelect({ emailHtml: html, emailJson: json, templateId });
			}
		} catch (error) {
			console.error("Failed to fetch template:", error);
		} finally {
			setLoading(false);
			//setSelectedTemplateId(null); // Comment out this line if you want to keep the highlight after selection
		}
	};

	const handleBlankTemplateSelection = () => {
		const blankTemplate = { name: "Blank Template", _id: "blank" };
		onSelect(blankTemplate);
		setSelectedTemplateId("blank"); // Highlight the blank template
	};

	const handleDialogOpen = () => {
		setOpen(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				const response = await axios.get("/templates");
				if (response.data && Array.isArray(response.data)) {
					setTemplates(response.data);
				}
			} catch (error) {
				console.error("Failed to fetch templates:", error);
			}
		};

		fetchTemplates();
	}, []);

	return (
		<Page>
			<Container maxWidth="lg">
				<Typography variant="h4" gutterBottom>
					Choose a Template
				</Typography>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card
							className={`${classes.card} ${classes.addCard} ${
								selectedTemplateId === "blank" ? classes.selectedCard : ""
							}`}
							onClick={handleBlankTemplateSelection}
						>
							<CardActionArea>
								<CardMedia className={classes.media} title={"Blank Template"}>
									<Typography variant="h6" component="div" color="inherit">
										Blank Template
									</Typography>
								</CardMedia>
							</CardActionArea>
						</Card>
					</Grid>

					{templates.map((template, index) => (
						<Grid item key={index} xs={12} sm={6} md={4} lg={3}>
							<Card
								className={`${classes.card} ${
									template.special && classes.addCard
								} ${
									selectedTemplateId === template._id
										? classes.selectedCard
										: ""
								}`}
								onClick={() => handleTemplateSelection(template._id)}
							>
								<CardActionArea>
									<CardMedia
										className={classes.media}
										image={template.image}
										title={template.title}
									>
										{loading && selectedTemplateId === template._id && (
											<div className={classes.loader}>
												<CircularProgress color="inherit" />
											</div>
										)}
									</CardMedia>
									<CardContent className={classes.content}>
										<Typography className={classes.title} gutterBottom>
											{template.name}
										</Typography>
										<div className={classes.detailsGrid}>
											<Typography className={classes.detail}>
												Created by {template.createdBy}
												<br />
												{format(new Date(template.createdAt), "PPP")}
											</Typography>
											<Typography className={classes.detail}>
												Updated by {template.updatedBy}
												<br />
												{format(new Date(template.updatedAt), "PPP")}
											</Typography>
										</div>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>
				<TemplateCreationDialog
					open={open}
					onOpen={handleDialogOpen}
					onClose={handleDialogClose}
					onContinue={handleTemplateSelection}
				/>
			</Container>
		</Page>
	);
}
