// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";
import Image from "@mui/icons-material/Image";
// ----------------------------------------------------------------------

const getIcon = (name) => (
	<SvgIconStyle
		src={`/static/icons/navbar/${name}.svg`}
		sx={{ width: "100%", height: "100%" }}
	/>
);

const getGif = (name) => (
	<Image
		src={`/static/icons/navbar/${name}.gif`}
		sx={{ width: "100%", height: "100%" }}
	/>
);

const ICONS = {
	blog: getIcon("ic_blog"),
	cart: getIcon("ic_cart"),
	chat: getIcon("ic_chat"),
	mail: getIcon("ic_mail"),
	user: getIcon("ic_user"),
	kanban: getIcon("ic_kanban"),
	banking: getIcon("ic_banking"),
	calendar: getIcon("ic_calendar"),
	ecommerce: getIcon("ic_ecommerce"),
	analytics: getGif("rate"),
	dashboard: getIcon("ic_dashboard"),
	booking: getIcon("ic_booking"),
};

// Define different sidebar configurations for different user roles
let sidebarConfig;
const userRole = "Admin"
// Check the user's role and assign the appropriate configuration
if (userRole === "Drug Manufacturer") {
	sidebarConfig = [
		{
			subheader: "Campaign Management",
			items: [
				{
					title: "Dashboard",
					path: PATH_DASHBOARD.general.app,
					icon: ICONS.analytics,
				},
                {
					title: "Campaigns",
					path: PATH_DASHBOARD.general.createCampaign,
					icon: ICONS.analytics,
				},
				{
					title: "Templates",
					path: PATH_DASHBOARD.general.campaignTemplates,
					icon: ICONS.analytics,
				},
				{
					title: "Analytics",
					path: PATH_DASHBOARD.general.analytics,
					icon: ICONS.analytics,
				},
			],
		},
	];
} else if (userRole === "NewsLetter Admin") {
	sidebarConfig = [
		{
			subheader: "NewsLetter Management",
			items: [
				{
					title: "Dashboard",
					path: PATH_DASHBOARD.general.app,
					icon: ICONS.analytics,
				},
                {
					title: "NewsLetters",
					path: PATH_DASHBOARD.general.createCampaign,
					icon: ICONS.analytics,
				},
				{
					title: "Templates",
					path: PATH_DASHBOARD.general.campaignTemplates,
					icon: ICONS.analytics,
				},
				{
					title: "NewsLetter Editor",
					path: PATH_DASHBOARD.general.analytics,
					icon: ICONS.analytics,
				},
			],
		},
	];
} else if (userRole === "Practice Owner") {
	sidebarConfig = [
		{
			subheader: "Campaign Management",
			items: [
                {
					title: "Dashboard",
					path: PATH_DASHBOARD.general.app,
					icon: ICONS.dashboard,
				},
				{
					title: "Create Campaign",
					path: PATH_DASHBOARD.general.createCampaign,
					icon: ICONS.analytics,
				},
				{
					title: "Scheduled Campaigns",
					path: PATH_DASHBOARD.general.scheduledCampaigns,
					icon: ICONS.calendar,
				},
				{
					title: "Reports",
					path: PATH_DASHBOARD.general.reports,
					icon: ICONS.analytics,
				},
				{
					title: "Gallery",
					path: PATH_DASHBOARD.general.gallery,
					icon: ICONS.analytics,
				},
                {
					title: "Templates",
					path: PATH_DASHBOARD.general.campaignTemplates,
					icon: ICONS.analytics,
				},
                {
					title: "Campaign Editor",
					path: PATH_DASHBOARD.general.easyEmailEditor,
					icon: ICONS.analytics,
				},
			],
		},
	];
} else if (userRole === "Admin") {
	// Original config with all options
	sidebarConfig = [
		// GENERAL
		// ----------------------------------------------------------------------
		// {
		// 	subheader: "General",
		// 	items: [
		// 		{
		// 			title: "Dashboard",
		// 			path: PATH_DASHBOARD.general.app,
		// 			icon: ICONS.dashboard,
		// 		},
		// 		{
		// 			title: "Analytics",
		// 			path: PATH_DASHBOARD.general.analytics,
		// 			icon: ICONS.analytics,
		// 		},
		// 	],
		// },

		// CAMPAIGN MANAGEMENT
		// ----------------------------------------------------------------------
		{
			subheader: "Campaign Management",
			items: [
				{
					title: "Campaigns",
					path: PATH_DASHBOARD.general.campaigns,
					icon: ICONS.analytics,
				},
				{
					title: "Campaign Templates",
					path: PATH_DASHBOARD.general.campaignTemplates,
					icon: ICONS.analytics,
				},
				// {
				// 	title: "Campaign Editor",
				// 	path: PATH_DASHBOARD.general.easyEmailEditor,
				// 	icon: ICONS.analytics,
				// },
				{
					title: "Gallery",
					path: PATH_DASHBOARD.general.gallery,
					icon: ICONS.analytics,
				},
				{
					title: "Campaign Calendar",
					path: PATH_DASHBOARD.calendar,
					icon: ICONS.calendar,
				},
				// {
				// 	title: "Campaign Details",
				// 	path: PATH_DASHBOARD.general.gallery,
				// 	icon: ICONS.analytics,
				// },
			],
		},

		// // USER MANAGEMENT
		// // ----------------------------------------------------------------------
		{
			subheader: "User Management",
			items: [
				{
					title: "User",
					path: PATH_DASHBOARD.user.root,
					icon: ICONS.user,
					children: [
						// { title: "Profile", path: PATH_DASHBOARD.user.profile },
						// { title: "Cards", path: PATH_DASHBOARD.user.cards },
						{ title: "List", path: PATH_DASHBOARD.user.list },
						{ title: "Create", path: PATH_DASHBOARD.user.newUser },
						// { title: "Edit", path: PATH_DASHBOARD.user.editById },
						// { title: "Account", path: PATH_DASHBOARD.user.account },
					],
				},
			],
		},

		// // APPLICATION FEATURES
		// // ----------------------------------------------------------------------
		// {
		// 	subheader: "App Features",
		// 	items: [
		// 		{ title: "Chat", path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
		// 		{
		// 			title: "Calendar",
		// 			path: PATH_DASHBOARD.calendar,
		// 			icon: ICONS.calendar,
		// 		},
		// 	],
		// },
	];
} else if (userRole === "SalesRep") {
        // Original config with all options
        sidebarConfig = [
            // GENERAL
            // ----------------------------------------------------------------------
            {
            	subheader: "General",
            	items: [
            		{
            			title: "Dashboard",
            			path: PATH_DASHBOARD.general.app,
            			icon: ICONS.dashboard,
            		},
            		{
            			title: "My Practices",
            			path: PATH_DASHBOARD.general.analytics,
            			icon: ICONS.analytics,
            		},
            	],
            },
    
            // CAMPAIGN MANAGEMENT
            // ----------------------------------------------------------------------
            {
                subheader: "Campaigns",
                items: [
                    {
                        title: "Campaign List",
                        path: PATH_DASHBOARD.general.campaignTemplates,
                        icon: ICONS.analytics,
                    },
                    {
                        title: "Campaign Calendar",
                        path: PATH_DASHBOARD.calendar,
                        icon: ICONS.calendar,
                    },
                ],
            },
    
        ];
}

export default sidebarConfig;
