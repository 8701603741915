// CustomizedVerticalSteppers.jsx
import React, { useEffect } from "react";
import {
	Box,
	Step,
	Paper,
	Button,
	Stepper,
	StepLabel,
	Typography,
	StepConnector,
	stepConnectorClasses,
	useTheme,
	Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import { useMediaQuery, CircularProgress } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.success.main,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.success.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderRadius: 1,
		borderTopWidth: 3,
		borderColor: theme.palette.divider,
	},
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	height: 22,
	display: "flex",
	alignItems: "center",
	color: theme.palette.text.disabled,
	...(ownerState.active && {
		color: theme.palette.success.main,
	}),
	"& .QontoStepIcon-completedIcon": {
		zIndex: 1,
		fontSize: 18,
		color: theme.palette.success.main,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

function QontoStepIcon({ active, completed }) {
	return (
		<QontoStepIconRoot ownerState={{ active }}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

export default function CustomizedVerticalSteppers({
	steps,
	campaign,
	isLoading,
}) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	// Retrieve 'step' from query params, default to 1
	const stepParam = searchParams.get("step");
	const totalSteps = steps.length;

	// Convert step from query param to integer, default to 1
	const initialStep =
		stepParam && !isNaN(parseInt(stepParam, 10)) && parseInt(stepParam, 10) > 0
			? parseInt(stepParam, 10)
			: 1;

	// Ensure initialStep does not exceed totalSteps
	const validInitialStep = initialStep > totalSteps ? totalSteps : initialStep;

	// Set 'step' to 1 if missing or invalid
	useEffect(() => {
		if (
			!stepParam ||
			isNaN(parseInt(stepParam, 10)) ||
			parseInt(stepParam, 10) < 1 ||
			parseInt(stepParam, 10) > totalSteps
		) {
			setSearchParams({ step: "1" }, { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepParam, totalSteps]);

	// Set activeStep based on validInitialStep
	const activeStep = validInitialStep - 1; // zero-based index

	// Handle step changes and update the 'step' query parameter
	const handleStepChange = (newStep) => {
		// Ensure newStep is within bounds
		const stepNumber = newStep + 1;
		if (stepNumber < 1) {
			setSearchParams({ step: "1" });
		} else if (stepNumber > totalSteps) {
			setSearchParams({ step: totalSteps.toString() });
		} else {
			setSearchParams({ step: stepNumber.toString() });
		}
	};

	const handleNext = () => {
		handleStepChange(activeStep + 1);
	};

	const handleBack = () => {
		handleStepChange(activeStep - 1);
	};

	const handleStepClick = (step) => () => {
		handleStepChange(step);
	};

	return (
		<Box display="flex" flexDirection={isMobile ? "column" : "row"}>
			<Box>
				<Stepper
					activeStep={activeStep}
					orientation={isMobile ? "horizontal" : "vertical"}
					connector={<QontoConnector />}
				>
					{steps.map(({ label }, index) => (
						<Step key={label}>
							<StepLabel
								StepIconComponent={(props) => (
									<QontoStepIcon
										active={activeStep === index}
										completed={activeStep > index}
										{...props}
									/>
								)}
								onClick={handleStepClick(index)}
								style={{ cursor: "pointer" }}
							>
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>
			<Box flex={1} ml={isMobile ? 0 : 4} mt={isMobile ? 4 : 0}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 2,
					}}
				>
					{campaign && (
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								gap: 1,
							}}
						>
							<Chip
								label={`Campaign Name: ${campaign.name}`}
								variant="outlined"
								color="primary"
							/>
							<Chip
								label={`Practice Id: ${campaign.practiceId}`}
								variant="outlined"
							/>
							<Chip
								label={`Campaign ID: ${campaign.legacyId}`}
								variant="outlined"
								color="primary"
							/>
							<Chip
								label={`Campaign Type: ${campaign.type}`}
								variant="outlined"
							/>
						</Box>
					)}
					<Box>
						<Button
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button>
						<Button
							variant="contained"
							onClick={handleNext}
							sx={{ mr: 1 }}
							disabled={isLoading || activeStep === totalSteps - 1}
						>
							{isLoading ? (
								<CircularProgress size={24} />
							) : activeStep === totalSteps - 1 ? (
								"Finish"
							) : (
								"Next"
							)}
						</Button>
					</Box>
				</Box>
				<Paper sx={{ p: 3, my: 3, minHeight: 120, bgcolor: "grey.50012" }}>
					{isLoading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="100%"
						>
							<CircularProgress />
						</Box>
					) : (
						<Typography>{steps[activeStep].content}</Typography>
					)}
				</Paper>
			</Box>
		</Box>
	);
}
